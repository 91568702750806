import classNames from 'classnames';

export function Section(props: { children: React.ReactNode }) {
  return <div className="space-y-related w-full">{props.children}</div>;
}

export function Column(props: { children: React.ReactNode; gap?: boolean; layout?: boolean; className?: string }) {
  return (
    <div
      className={classNames(
        'flex-1 flex flex-col last:first:[&:not([class*="justify-self"])]:*:flex-1 self-stretch w-full',
        props.className,
        {
          'space-y-2': props.gap,
        },
      )}
    >
      {props.children}
    </div>
  );
}

export function Row(props: {
  children: React.ReactNode;
  between?: boolean;
  center?: boolean;
  breakpoint?: 'sm' | 'md';
  className?: string;
  nowrap?: boolean;
}) {
  return (
    <div
      className={classNames('flex', props.className, {
        'gap-x-related items-center': !props.breakpoint,
        'justify-center': props.center,
        'justify-between': props.between,
        'flex-wrap': !props.nowrap,
        'sm:items-center max-sm:flex-col sm:gap-x-related': props.breakpoint === 'sm',
        'sm:gap-x-related': !props.between && props.breakpoint === 'sm',
        'max-sm:gap-y-related': props.breakpoint === 'sm' && !props.between,
        'md:items-center max-md:flex-col md:gap-x-related': props.breakpoint === 'md',
        'md:gap-x-related': !props.between && props.breakpoint === 'md',
        'max-md:gap-y-related': props.breakpoint && props.between,
      })}
    >
      {props.children}
    </div>
  );
}

export default function Sections(props: { children: React.ReactNode }) {
  return <div className="space-y-unrelated w-full">{props.children}</div>;
}
